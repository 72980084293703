<!--
    Created by 程雨喵'mac on 2023/12/12.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：主数据管理-主数据列表
-->
<style lang="less">
#main-data-list-content {
  .main-data-error-item {
    font-size: 12px;
  }
  .main-data-table-row {}
  .ivu-table-expanded-cell {
    padding: 0 !important;
  }
}
</style>

<template>
  <div id="main-data-list-content">
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        @on-click="_clickSetting"
      />
    </div>
    <div
      v-if="mainKeyValue.length > 0"
      class="margin-primary-top"
    >
      <Alert
        type="error"
        show-icon
      >
        主数据缺失-请尽快处理
        <template slot="desc">
          <div
            v-for="(item, i) in mainKeyValue"
            :key="i"
            class="main-data-error-item"
          >
            {{ item.label }}缺失，请马上
            <yg-button
              type="text"
              @click="_clickMainDataDeal(item)"
            >
              点击生成
            </yg-button>
          </div>
        </template>
      </Alert>
    </div>
    <div class="padding-primary-top">
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        :row-class-name="getRowClassName"
        :page-size-infinite="true"
        @on-page-change="onChangePage"
      />
    </div>
    <!--弹窗：提交(新增/编辑)-->
    <v-submit
      v-if="!!dialog.submitModel"
      :title="'主类别名称'"
      :is-main="true"
      :model="dialog.submitModel"
      :data-list="tableData"
      @on-close="dialog.submitModel = null"
      @on-sure="_clickSubmit"
    />
    <!--弹窗：设置子类-->
    <v-setting-submit
      v-if="!!dialog.settingModel"
      :model="dialog.settingModel"
      @on-close="onCloseSetting"
    />
  </div>
</template>

<script>
import DictApiSet from '@/request/api/DictApiSet';
import { MainDataModel } from './model/MainDataModel';
import ColumnMixin from '@/pages/030__basicSetting/mainDataList/mixin/tableColumn';
import vSubmit from './view/submit.vue';
import vSettingSubmit from './view/setting.vue';
import vTableExpand from './view/table-expand.vue';
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { vSubmit, vSettingSubmit, vTableExpand },
  mixins: [ColumnMixin],
  data () {
    return {
      tableColumns: [],
      settingItems: [
        BaseSettingModel.initData('新增', 'add', 'md-add-circle'),
        BaseSettingModel.initData('刷新', 'refresh', 'md-refresh-circle')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      dialog: {
        submitModel: null,
        settingModel: null
      },
      // 前端用到的所有主数据
      mainKeyValue: []
    };
  },
  created () {
    this.tableColumnsOrg[0].setExpand().setRender((h, params) => {
      return h(vTableExpand, {
        props: {
          model: params.row
        },
        on: {
          'on-setting': () => {
            const model = new MainDataModel();
            model.initData(params.row, JSON.parse(JSON.stringify(this.$config.mainKeyValue)));
            this.dialog.settingModel = model;
          }
        }
      });
    });
    // this.tableColumnsOrg[0].setExpand(vTableExpand);
    this.tableColumns = this.tableColumnsOrg;
    this.requestData();
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    getRowClassName () {
      return 'main-data-table-row';
    },
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    // 分页
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    onCloseSetting (e) {
      this.dialog.settingModel = null;
      if (e) {
        this.requestData();
      }
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    // 设置功能
    _clickSetting (key) {
      switch (key) {
      case 'add':
        this.dialog.submitModel = MainDataModel.createAdditionModel();
        break;
      case 'refresh':
        this.page.current = 1;
        this.requestData();
        break;
      }
    },
    // 点击提交
    _clickSubmit () {
      const res = this.dialog.submitModel.id ? this.dialog.submitModel.checkValidEditSubmit() : this.dialog.submitModel.checkValidAdditionSubmit();
      console.log(res);
      if (!res.success) {
        this.$Message.warning(res.msg);
      } else {
        this.requestAddOrEdit(res.params);
      }
    },
    // 点击立即处理主数据
    _clickMainDataDeal (item) {
      const params = {
        name: item.label,
        type: item.key
      };
      this.requestAddOrEdit(params);
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    // 请求数据
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = DictApiSet.dictTypeList;
      api.params = {
        // pageSize: this.$config.pageSize,
        // 这里拉全量
        pageSize: 99999,
        pageNo: this.page.current
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        const mainKeyValue = JSON.parse(JSON.stringify(this.$config.mainKeyValue));
        this.tableData = MainDataModel.initListWithDataList(resData.rows, mainKeyValue);
        // 查看是否缺失主数据
        if (this.tableData.length) {
          this.tableData.forEach(v => {
            // 如果数据中有那就踢掉
            const i = mainKeyValue.findIndex(m => v.type === m.key);
            if (i !== -1) {
              mainKeyValue.splice(i, 1);
            }
          });
        }
        this.mainKeyValue = mainKeyValue;
      });
    },
    // 切换状态
    requestChangeStatus (row, status) {
      const api = DictApiSet.dictTypeChangeStatus;
      api.params = {
        id: row.id,
        status: status ? 1 : 0
      };
      this.$http(api)
        .then(res => {
          console.log(res);
          this.$Message.success('操作成功');
          this.requestData();
        })
        .catch(e => {
          row.status = status ? 0 : 1;
          row.h5StatusSwitch = !status;
        });
    },
    // 删除
    requestDel (row) {
      const api = DictApiSet.dictTypeDel;
      api.params = { id: row.id };
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        this.requestData();
      });
    },
    // 新增/编辑
    requestAddOrEdit (params) {
      const api = (this.dialog.submitModel && this.dialog.submitModel.id) ? DictApiSet.dictTypeEdit : DictApiSet.dictTypeAdd;
      api.params = params;
      console.log(api);
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        this.dialog.submitModel = null;
        this.requestData();
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
