/**
 * @date:2024/1/3
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 * 字典信息相关请求
 */

import BaseApi from '@/request/base/BaseApi';

class DictApiSet {
  constructor () {
    // 字典类型相关【爹】
    this.dictTypeList = BaseApi.createModel('字典类型信息列表', '/opmp/dict/type/list', true).setMethod(0);
    this.dictTypeInfo = BaseApi.createModel('字典类型信息', '/opmp/dict/type/info', true).setMethod(0, false);
    this.dictTypeEdit = BaseApi.createModel('编辑字典类型信息', '/opmp/dict/type/edit', true);
    this.dictTypeAdd = BaseApi.createModel('新增编辑字典类型信息', '/opmp/dict/type/add', true);
    this.dictTypeChangeStatus = BaseApi.createModel('启用禁用字典类型', '/opmp/dict/type/changeStatus', true).setMethod(2);
    this.dictTypeDel = BaseApi.createModel('删除字典类型', '/opmp/dict/type/delete', true).setMethod(2);
    // 字典数据相关【儿子】
    this.dictDataList = BaseApi.createModel('字典数据信息列表', '/opmp/dict/data/list', true).setMethod(0);
    this.dictDataInfo = BaseApi.createModel('字典数据信息', '/opmp/dict/data/info', true).setMethod(0, false);
    this.dictDataEdit = BaseApi.createModel('编辑字典数据信息', '/opmp/dict/data/edit', true);
    this.dictDataAdd = BaseApi.createModel('新增编辑字典数据信息', '/opmp/dict/data/add', true);
    this.dictDataChangeStatus = BaseApi.createModel('启用禁用字典数据', '/opmp/dict/data/changeStatus', true).setMethod(2);
    this.dictDataDel = BaseApi.createModel('删除字典数据', '/opmp/dict/data/delete', true).setMethod(2);
  }
}

export default new DictApiSet();
