import { MainDataModel } from '@/pages/030__basicSetting/mainDataList/model/MainDataModel';

export default {
  data () {
    return {
      tableColumnsOrg: [
        BaseTableColumn.initData(''),
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('name', '主类别名称').setSpan({}),
        BaseTableColumn.initData('type', '类别').setSpan({ canCopy: true }),
        BaseTableColumn.initData('h5IsImportantData', '重要程度').setStatus([
          { value: 0, type: 1 },
          { value: 1, type: 5 }
        ], 'h5IsImportantDataDesc'),
        BaseTableColumn.initData('h5StatusSwitch', '状态').setSpan({}).setSwitch((e, row, index) => {
          row.h5StatusSwitch = e;
          // 如果是重要数据就不可以停用！！！
          if (row.h5IsImportantData === 1 && !row.h5StatusSwitch) {
            this.$ygLoading.show();
            this.$Message.error('重要数据无法禁用');
            setTimeout(() => {
              row.h5StatusSwitch = !e;
              this.$ygLoading.close();
            }, 200);
            return;
          }
          this.$Modal.confirm({
            title: '操作提示',
            content: `是否确认${e ? '启用' : '停用'}`,
            onOk: () => {
              this.requestChangeStatus(row, e);
            },
            onCancel: () => {
              row.h5StatusSwitch = !e;
              this.$Message.info('操作已取消');
            }
          });
        }),
        BaseTableColumn.initData('setting', '操作').setWidth(120).setAlign('center').setButtons([
          { title: '编辑', name: 'edit' },
          { title: '设置子类', name: 'setting' },
          { title: '删除', name: 'del' }
        ], ({ name }, row, index) => {
          const model = new MainDataModel();
          model.initData(row, JSON.parse(JSON.stringify(this.$config.mainKeyValue)));
          switch (name) {
          case 'edit':
            this.dialog.submitModel = model;
            break;
          case 'setting':
            this.dialog.settingModel = model;
            break;
          case 'del':
            if (row.h5IsImportantData) {
              this.$Message.error('重要数据不可删除');
            } else {
              this.$Modal.confirm({
                title: '操作提示',
                content: '删除后无法找回，是否确认删除？',
                onOk: () => {
                  this.requestDel(row);
                },
                onCancel: () => {}
              });
            }
            break;
          }
        })
      ]
    };
  }
};
