<!--
    Created by 程雨喵'mac on 2023/12/28.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：主数据配置 - 新增/编辑
    功能概述：新增、编辑、启用、禁用
-->
<style lang="less">

</style>

<template>
  <yg-modal
    width="600"
    :title="(model.id ? '编辑' : '新增') + title"
    sure-text="保存"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledMsg"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      style="min-height: 200px"
    >
      <div class="main-data-submit-content">
        <Form>
          <FormItem
            v-for="(item, index) in formItems"
            :key="index"
            :label="item.label"
            :label-width="item.labelWidth"
            :required="item.isRequired"
          >
            <Input
              v-if="item.tagName === 'Input'"
              v-model="model[item.key]"
              type="text"
              :minlength="item.minLength"
              :maxlength="item.maxLength"
              :placeholder="'请输入' + item.label"
              :disabled="isEditImportantData"
              @input="checkSubmit"
            />
          </FormItem>
        </Form>
      </div>
    </div>
  </yg-modal>
</template>

<script>
export default {
  components: {},
  props: {
    // 是否主数据 true 主数据 ， false 子数据
    isMain: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    // 数据
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 标题
    title: {
      type: String,
      default: () => {
        return '';
      }
    }
  },
  data () {
    return {
      buttonDisabled: !this.model.id,
      buttonDisabledMsg: '',
      // 表单数据
      mainFormItems: [
        {
          label: '主类别名称',
          labelWidth: 135,
          key: 'name',
          tagName: 'Input',
          isRequired: true,
          minLength: '4',
          maxLength: '20'
        },
        {
          label: '类型',
          labelWidth: 135,
          key: 'type',
          tagName: 'Input',
          isRequired: true,
          minLength: '4',
          maxLength: '20'
        }
      ],
      subFormItems: [
        {
          label: '子类型键名',
          labelWidth: 135,
          key: 'label',
          tagName: 'Input',
          isRequired: true,
          minLength: '4',
          maxLength: '20'
        },
        {
          label: '键值',
          labelWidth: 135,
          key: 'value',
          tagName: 'Input',
          isRequired: true,
          minLength: '4',
          maxLength: '200'
        }
      ]
    };
  },
  computed: {
    formItems () {
      if (this.isMain) {
        return this.mainFormItems;
      }
      return this.subFormItems;
    },
    isEditImportantData () {
      return !!(this.model.id && this.model.h5IsImportantData === 1);
    }
  },
  created () {
    console.log(this.model);
  },
  methods: {
    onSure () {
      if (this.isEditImportantData) {
        return this.$Message.error('重要数据无法编辑');
      }
      this.$emit('on-sure');
    },
    onClose () {
      this.$emit('on-close');
    },
    checkSubmit () {
      const item = this.formItems.find(v => v.isRequired && !this.model[v.key]);
      this.buttonDisabled = !!item;
      this.buttonDisabledMsg = item ? (item.label + '不可为空') : '';
    }
  }
};
</script>
