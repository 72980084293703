<!--
    Created by 程雨喵'mac on 2024/2/29.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：
    功能概述：
-->
<style lang="less">
.main-data-expand {
  padding: @containerGap 50px 30px;
  background-color: @backColorLight;
  //border-radius: @borderRadiusMid;
  .main-data-setting-content {
    background-color: @backColorNormal;
    border-radius: @borderRadiusMid;
    overflow: hidden;
    box-shadow: @shadowMin;
    .no-sub-data {
      text-align: center;
      color: @textColorLight;
    }
    .ivu-table-header {
      .ivu-table-cell {
        span {
          color: @textColorLight !important;
        }
      }
    }
    .main-data-expand-row {
      .ivu-table-column-center {
        background-color: @backColorNormal !important;
        color: @textColorLight !important;
      }
    }
  }
}
</style>

<template>
  <div class="main-data-expand">
    <div class="main-data-setting-content">
      <div v-if="tableData.length">
        <yg-table
          :columns="tableColumns"
          :data="tableData"
          :row-class-name="getRowClassName"
          :page-size-infinite="true"
          :max-height="9999"
        />
      </div>
      <div
        v-else
        class="no-sub-data"
      >
        <div class="text">
          暂未设置任何子类
        </div>
        <yg-button
          type="text"
          @click="_clickSetting"
        >
          立即设置
        </yg-button>
      </div>
    </div>
  </div>
</template>

<script>
import DictApiSet from '@/request/api/DictApiSet';
import { SubDataModel } from '@/pages/030__basicSetting/mainDataList/model/SubDataModel';

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      settingItems: [
        BaseSettingModel.initData('添加子类别', 'add', 'md-add-circle'),
        BaseSettingModel.initData('刷新数据', 'refresh', 'md-refresh-circle')
      ],
      tableColumns: [
        // BaseTableColumn.initData('index'),
        BaseTableColumn.initData('label', '子类别名称').setSpan({}),
        BaseTableColumn.initData('value', '子类别值').setSpan({}),
        BaseTableColumn.initData('h5StatusStr', '状态').setSpan({}),
        BaseTableColumn.initData('createTime', '创建时间').setSpan({}),
        BaseTableColumn.initData('createUser', '创建人').setSpan({})
      ],
      dialog: {
        submitModel: null
      },
      tableData: []
    };
  },
  watch: {
    model () {
      this.requestData();
    }
  },
  created () {
    this.requestData();
  },
  methods: {
    getRowClassName () {
      return 'main-data-expand-row';
    },
    /** ******************* 点击事件开始 ******************* **/
    // 设置功能
    _clickSetting (key) {
      this.$emit('on-setting');
    },
    _clickSubmit () {
      const res = this.dialog.submitModel.id ? this.dialog.submitModel.checkValidEditSubmit() : this.dialog.submitModel.checkValidAdditionSubmit();
      console.log(res);
      if (!res.success) {
        this.$Message.warning(res.msg);
      } else {
        this.requestAddOrEdit(res.params);
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      this.tableData = [];
      const api = DictApiSet.dictDataList;
      api.params = {
        dictType: this.model.type,
        pageSize: 999,
        pageNo: 1
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.tableData = SubDataModel.initListWithDataList(resData.rows);
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
