import BaseModel from '@/libs/base/dataModel/BaseModel';
import { isValidString1 } from '@/libs/regularUtils';

export class SubDataModel extends BaseModel {
  constructor () {
    super();
    // 数据ID
    this.id = '';
    // 字典类型标识（唯一）
    this.dictType = '';
    // 数据标签
    this.label = '';
    // 数据键值
    this.value = '';
    // 排序
    this.sort = '';
    // 数据状态（1：启用，0：禁用）
    this.status = '';
    // 字典类型名称
    this.name = '';
    // 字典类型标识（唯一）
    this.type = '';
    this.createTime = '';
    this.createUser = '';
    // 前端自有
    this.h5StatusSwitch = false;
    this.h5StatusStr = '';
  }

  initData (resData) {
    super.initData(resData);
    this.dictType = this.type;
    this.h5StatusSwitch = !!this.status;
    this.h5StatusStr = this.h5StatusSwitch ? '已启用' : '已禁用';
    return this;
  }

  static createFakeDataList (len = 30) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        id: '000101010' + i,
        label: 'father' + i,
        value: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        dictType: 'son' + i,
        status: i % 2
      };
      list.push(dic);
    }
    return list;
  }

  /**
   * 数据具体校验-新增
   * @returns {*}
   */
  checkValidAdditionSubmit () {
    // 专项校验
    if (this.label.length < 1 || this.label.length > 20) {
      return BaseModel.getValidRes(false, '请输入1-20位主类别名称名称');
    } else if (!isValidString1(this.label)) {
      if (this.label !== '公司/企业') {
        return BaseModel.getValidRes(false, '请输入有效字符');
      }
    }
    return BaseModel.getValidRes(true, '', {
      dictType: this.dictType,
      label: this.label,
      value: this.value
    });
  }

  /**
   * 数据具体校验-编辑
   * @returns {*}
   */
  checkValidEditSubmit () {
    // 专项校验
    if (this.label.length < 1 || this.label.length > 20) {
      return BaseModel.getValidRes(false, '请输入1-20位主类别名称名称');
    } else if (!isValidString1(this.label)) {
      return BaseModel.getValidRes(false, '请输入有效字符');
    }
    return BaseModel.getValidRes(true, '', {
      id: this.id,
      label: this.label,
      value: this.value
    });
  }

  static createAdditionModel (data) {
    const model = new this();
    model.dictType = data.type;
    return model;
  }
}
