<!--
    Created by 程雨喵'mac on 2023/12/28.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：主数据配置 - 设置子类
    功能概述：新增、编辑、启用、禁用
-->
<style lang="less">
.main-data-setting-title {
  .text-color-strong {
    margin-left: @containerGap;
    color: @adornColor;
    font-size: 12px;
  }
}
.main-data-setting-content {
  padding: @containerGap;
  border-radius: @borderRadiusMin;
  height: 100%;
  background-color: @backColorStrong;
}
</style>

<template>
  <yg-drawer
    width="1200"
    :back-type="2"
    sure-text="提交"
    :show-bottom="false"
    @on-close="onClose"
  >
    <p
      slot="title"
      class="main-data-setting-title"
    >
      <span>{{ model.name }}</span>
      <span class="text-color-strong">{{ isEditImportantData ? '*注：主要数据，谨慎操作' : '' }}</span>
    </p>
    <div
      slot="content"
      class="main-data-setting-content"
    >
      <div>
        <yg-setting-area
          :items="settingItems"
          :total="page.total"
          @on-click="_clickSetting"
        />
      </div>
      <div class="padding-primary-top">
        <yg-table
          :columns="tableColumns"
          :data="tableData"
          :page="page"
          @on-page-change="onChangePage"
        />
      </div>
      <!--弹窗：提交(新增/编辑)-->
      <v-submit
        v-if="!!dialog.submitModel"
        :title="model.name"
        :is-main="false"
        :model="dialog.submitModel"
        @on-close="dialog.submitModel = null"
        @on-sure="_clickSubmit"
      />
    </div>
  </yg-drawer>
</template>

<script>
import DictApiSet from '@/request/api/DictApiSet';
import { SubDataModel } from '@/pages/030__basicSetting/mainDataList/model/SubDataModel';
import vSubmit from './submit.vue';

export default {
  components: { vSubmit },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      settingItems: [
        BaseSettingModel.initData('添加子类别', 'add', 'md-add-circle'),
        BaseSettingModel.initData('刷新数据', 'refresh', 'md-refresh-circle')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('label', '名称').setSpan({}),
        BaseTableColumn.initData('value', '值').setSpan({}),
        BaseTableColumn.initData('h5StatusSwitch', '状态').setSwitch((e, row, index) => {
          row.h5StatusSwitch = e;
          this.$Modal.confirm({
            title: '操作提示',
            content: `是否确认${e ? '启用' : '停用'}`,
            onOk: () => {
              this.requestChangeStatus(row, e);
            },
            onCancel: () => {
              row.h5StatusSwitch = !e;
              this.$Message.info('操作已取消');
            }
          });
        }),
        BaseTableColumn.initData('setting', '操作').setWidth(140).setAlign('center').setButtons([
          { title: '编辑', name: 'edit' }
        ], ({ name }, row, index) => {
          switch (name) {
          case 'edit':
            this.dialog.submitModel = SubDataModel.initModelWithData(row);
            break;
          }
        })
      ],
      dialog: {
        submitModel: null
      },
      needRefreshList: false
    };
  },
  computed: {
    isEditImportantData () {
      return !!(this.model.id && this.model.h5IsImportantData === 1);
    }
  },
  created () {
    console.log(this.model);
    this.requestData();
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    // 分页
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    // 设置功能
    _clickSetting (key) {
      switch (key) {
      case 'add':
        this.dialog.submitModel = SubDataModel.createAdditionModel(this.model);
        break;
      case 'refresh':
        this.page.current = 1;
        this.requestData();
        break;
      }
    },
    _clickSubmit () {
      const res = this.dialog.submitModel.id ? this.dialog.submitModel.checkValidEditSubmit() : this.dialog.submitModel.checkValidAdditionSubmit();
      console.log(res);
      if (!res.success) {
        this.$Message.warning(res.msg);
      } else {
        this.requestAddOrEdit(res.params);
      }
    },
    onClose () {
      this.$emit('on-close', this.needRefreshList);
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = DictApiSet.dictDataList;
      api.params = {
        dictType: this.model.type,
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = SubDataModel.initListWithDataList(resData.rows);
      });
    },
    // 切换状态
    requestChangeStatus (row, status) {
      const api = DictApiSet.dictDataChangeStatus;
      api.params = {
        id: row.id,
        status: status ? 1 : 0
      };
      this.$http(api)
        .then(res => {
          console.log(res);
          this.$Message.success('操作成功');
          this.needRefreshList = true;
          this.requestData();
        })
        .catch(e => {
          row.status = status ? 0 : 1;
          row.h5StatusSwitch = !status;
        });
    },
    // 新增
    requestAddOrEdit (params) {
      const api = (this.dialog.submitModel && this.dialog.submitModel.id) ? DictApiSet.dictDataEdit : DictApiSet.dictDataAdd;
      api.params = params;
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        this.dialog.submitModel = null;
        this.needRefreshList = true;
        this.requestData();
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
