import BaseModel from '@/libs/base/dataModel/BaseModel';
import { isValidString1 } from '@/libs/regularUtils';

const mainDataDic = {
  0: '次要数据',
  1: '重要数据'
};

export class MainDataModel extends BaseModel {
  constructor () {
    super();
    // 字典类型ID
    this.id = '';
    // 字典类型名称
    this.name = '';
    // 字典类型标识（唯一）
    this.type = '';
    // 数据状态（1：启用，0：禁用）
    this.status = '';
    // 备注
    this.remark = '';
    // 前端自有
    this.h5StatusSwitch = false;
    // 前端自有-是否是主数据 (0: 不重要，1：系统级别数据)
    this.h5IsImportantData = 0;
    this.h5IsImportantDataDesc = '';
  }

  initData (resData, mainKeyValueList) {
    super.initData(resData);
    this.h5StatusSwitch = !!this.status;
    this.h5IsImportantData = mainKeyValueList.find(v => v.key === this.type) ? 1 : 0;
    this.h5IsImportantDataDesc = mainDataDic[this.h5IsImportantData];
    return this;
  }

  static initListWithDataList (dataList, mainKeyValueList) {
    const list = [];
    if (dataList && Array.isArray(dataList) && dataList.length) {
      const len = dataList.length;
      // 这里用遍历效率最高的for循环
      for (let i = 0; i < len; i++) {
        const data = dataList[i];
        const model = new MainDataModel();
        model.initData(data, mainKeyValueList);
        list.push(model);
      }
    }
    return list;
  }

  static createFakeDataList (len = 30) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        id: '000101010' + i,
        name: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        type: 'father' + i,
        status: i % 2
      };
      list.push(dic);
    }
    return list;
  }

  /**
   * 数据具体校验-新增
   * @returns {*}
   */
  checkValidAdditionSubmit () {
    // 专项校验
    if (this.name.length < 4 || this.name.length > 20) {
      return BaseModel.getValidRes(false, '请输入4-20位主类别名称名称');
    } else if (!isValidString1(this.name)) {
      return BaseModel.getValidRes(false, '请输入有效字符');
    }
    return BaseModel.getValidRes(true, '', {
      name: this.name,
      type: this.type
    });
  }

  /**
   * 数据具体校验-编辑
   * @returns {*}
   */
  checkValidEditSubmit () {
    // 专项校验
    if (this.name.length < 4 || this.name.length > 20) {
      return BaseModel.getValidRes(false, '请输入4-20位主类别名称名称');
    } else if (!isValidString1(this.name)) {
      return BaseModel.getValidRes(false, '请输入有效字符');
    }
    return BaseModel.getValidRes(true, '', {
      id: this.id,
      name: this.name,
      type: this.type
    });
  }

  static createAdditionModel () {
    return new this();
  }
}
